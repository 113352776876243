import { Timestamp } from 'firebase-admin/firestore'
import { z } from 'zod'

import { VoucherSeries } from '@contracts/enums/VoucherSeries'
import { InvoiceActionView } from '@contracts/types/BillectaTypes'
import { ParkingSession } from '@contracts/types/ParkingSession'
import { PaymentOptions } from '@contracts/types/PaymentOptions'
import { Permit } from '@contracts/types/Permit'
import { Site } from '@contracts/types/Site'
import { StripePaymentIntent } from '@contracts/types/StripePaymentIntent'

import { SessionStatus } from '@pure/libs/SessionStatusHelper'
import { GetDrifterSessionResponse, SwishPaymentIntent } from '@pure/swagger/DrifterTypesDerived'

import { CameraEvent } from './CameraEvent'

export type SessionBase = {
  id: string
  // Note: migration is in process, need to handle both
  createdAt: Timestamp | string
  billectaInvoiceActionView?: InvoiceActionView | null // owned by billecta
  payment?: Payment | null // Payment and receipt info
  swishPaymentIntent?: SwishPaymentIntent // Deprecated, used in old swish solution
  stripePaymentIntent?: StripePaymentIntent // object with three properties that store data owned by stripe such as charge, paymentMethod and paymentIntent
  // deprecated, use sessionEvents instead
  site?: SessionSite // Stripped version of site object, used for debugging purposes to know the exact siteconfig that was used when creating session
  request?: OnSessionClosedRequest // deprecated, should be removed in future releases. This object came from LBE.
  collectiveInvoice?: {
    collectiveInvoiceId?: string
    scheduledInvoiceSendoutAt?: string
    billectaPublicId?: string // typically an pointing to a id to a billecta customer
    creditBillectaPublicId?: string // typically an pointing to am object that represent Drifter in billecta system.
    isStillPendingDueToMinimumAmount?: boolean
    pendingMinimumAmountReached?: boolean
    pendingMinimumAmountCutoffAt?: string
  } | null
  isHikerSession?: boolean
  // Note: migration is in process, need to handle both
  updatedAt?: Timestamp | string
  sessionStatus?: SessionStatus
  durationSeconds?: number
  revenueCents?: number
}

// Deprecated old object moddelling a LBE session.
export type SessionOld = SessionBase & {
  events: {
    [sessionEventName: string]: SessionEvent
  }
  sessionEvents?: SessionEvent[]
  backendSession: GetDrifterSessionResponse
}

export type Session = SessionBase & {
  parkingSession?: ParkingSession
  // @TODO: To be remove once the sessionStatus is fully migrated
  falseSession?: boolean
}

export type SessionFalse = Session & {
  cameraEvents: CameraEvent[]
}

export type SessionEventBase = {
  name: SessionEventName // What event was triggered
  description?: string // More data on what happened
  shouldWarn?: boolean // used in slack channels to show warning icon
  metaData?: Record<string, any> // used to store objects not owned by us on an event (sucha as a billecta object)
  shouldSlack?: boolean
}
export type PermitSessionEventBase = {
  type?: 'permit'
  startedAt?: string
  permit?: Permit
}
export type SessionEventRequest = PermitSessionEventBase &
  SessionEventBase & {
    createdAt?: string
  }
export type SessionEvent = SessionEventBase & {
  createdAt: string
}
export enum SessionEventName {
  // Parking events
  PARKING_SESSION_ENDED = 'PARKING_SESSION_ENDED',
  PARKING_OFFENSE = 'PARKING_OFFENSE',
  PARKING_FREE = 'PARKING_FREE',
  PARKING_NORMAL = 'PARKING_NORMAL',
  PARKING_WITH_PERMIT = 'PARKING_WITH_PERMIT',
  PARKING_AUTO_ENDED = 'PARKING_AUTO_ENDED',
  PARKING_SESSION_CLOSE_SCHEDULED = 'PARKING_SESSION_CLOSE_SCHEDULED',
  PARKING_SESSION_CLOSED = 'PARKING_SESSION_CLOSED',
  PARKING_FALSE_SESSION = 'PARKING_FALSE_SESSION',
  PARKING_CONVERTED_TO_GUEST_FREE = 'PARKING_CONVERTED_TO_GUEST_FREE',
  // Payment events
  STRIPE_PAYMENT_INTENT_CREATED = 'STRIPE_PAYMENT_INTENT_CREATED',
  STRIPE_PAYMENT_INTENT_FAILED = 'STRIPE_PAYMENT_INTENT_FAILED',
  BILLECTA_INVOICE_INTENT_CREATED = 'BILLECTA_INVOICE_INTENT_CREATED',
  BILLECTA_DEBTOR_CREATED = 'BILLECTA_DEBTOR_CREATED',
  BILLECTA_INVOICE_CREATED = 'BILLECTA_INVOICE_CREATED',
  BILLECTA_INVOICE_INTENT_FAILED = 'BILLECTA_INVOICE_INTENT_FAILED',
  BILLECTA_PENDING_INVOICE_DELETED = 'BILLECTA_PENDING_INVOICE_DELETED',
  BILLECTA_INVOICE_CREATION_FAILED = 'BILLECTA_INVOICE_CREATION_FAILED',
  BILLECTA_INVOICE_UPDATED = 'BILLECTA_INVOICE_UPDATED',
  BILLECTA_INVOICE_CANCELLED = 'BILLECTA_INVOICE_CANCELLED', // deprecated but kept for old data ( https://drifterworld.atlassian.net/wiki/spaces/DMA/pages/71368713/Invoice+and+Payment+statuses )
  BILLECTA_INVOICE_CREDITED = 'BILLECTA_INVOICE_CREDITED',
  BILLECTA_INVOICE_PAID = 'BILLECTA_INVOICE_PAID',
  STRIPE_CHARGE_REFUNDED = 'STRIPE_CHARGE_REFUNDED',
  PAYMENT_PAID = 'PAYMENT_PAID',
  PAYMENT_CANCELLED = 'PAYMENT_CANCELLED',
  INTERNAL_ERROR = 'INTERNAL_ERROR',
  PAYMENT_SESSION_ABORTED = 'PAYMENT_SESSION_ABORTED',

  // Collective events
  BILLECTA_INVOICE_CREATED_FOR_COLLECTIVE = 'BILLECTA_INVOICE_CREATED_FOR_COLLECTIVE',
  CANCELLED_FROM_THE_COLLECTIVE = 'CANCELLED_FROM_THE_COLLECTIVE',
  CANCELLED_WITH_THE_COLLECTIVE = 'CANCELLED_WITH_THE_COLLECTIVE',
  REMOVED_FROM_THE_COLLECTIVE = 'REMOVED_FROM_THE_COLLECTIVE',
  REMAPPED_TO_THE_NEW_COLLECTIVE = 'REMAPPED_TO_THE_NEW_COLLECTIVE',
  CANCELLED_INITIALLY_DUE_TO_MINIMUM_VALUE_NOT_REACHED = 'CANCELLED_INITIALLY_DUE_TO_MINIMUM_VALUE_NOT_REACHED',
  CANCELLED_PERMANENTLY_DUE_TO_MININUM_VALUE_NOT_REACHED = 'CANCELLED_PERMANENTLY_DUE_TO_MININUM_VALUE_NOT_REACHED',
  UNCANCELLED_DUE_TO_MINIMUM_AMOUNT_REACHED = 'UNCANCELLED_DUE_TO_MINIMUM_AMOUNT_REACHED',

  // Permit events
  PERMIT_VALIDATED = 'PERMIT_VALIDATED',
  PERMIT_VALIDATION_FAILED = 'PERMIT_VALIDATION_FAILED',

  // Other
  LBE_SESSION_UPDATED = 'LBE_SESSION_UPDATED',
  PARKING_SESSION_FIXED_FREE_TIME = 'PARKING_SESSION_FIXED_FREE_TIME',
  SKIPPED_INVOICE = 'SKIPPED_INVOICE',
  SESSION_PAYMENT_RECALCULATED = 'SESSION_PAYMENT_RECALCULATED'
}

export type PaymentInfo = {
  status: 'paid' | 'unpaid' | 'pending' | 'failed' | 'cancelled' | 'credited' | 'aborted' // trivial
  currencyCode?: string // SEK // trivial
  method?: {
    paymentOption: PaymentOptions // trivial
    reference: string // stripePaymentIntentId, swichPaymentIntentId, billectaInvoiceId
  }
  paymentLink?: string // used in retool to deeplink to stripe / billecta
  bookkeepingLink?: string // used in retool to deeplink to fortnox
  voucherSeries?: VoucherSeries // used to identify what voucher series was used in fortnox
  refundedAt?: string // trivial
}

// Payment object that is used to store payment information and receipt information.
export type Payment = {
  createdAt: string
  vatPercent: number
  paymentLink?: string
  fees: {
    parkingFee?: number // TODO: make mandatory make mandatory after new session logic
    totalParkingFee: number
    totalParkingFeeIncludingVAT: number
    administrationFee: number
    administrationFeeInclVAT: number
    offenseFees: { [offenseType: string]: number }
    offenseFee?: number
    totalFee: number
    totalVAT: number
    totalFeeIncludedVAT: number
  }
} & PaymentInfo

export type SessionPaymentRecalculateStatus = {
  status: 'unchanged' | 'updated'
  payment?: Payment
}

export enum DeliveryOption {
  EMAIL = 'EMAIL',
  SMS = 'SMS'
}

// This object is created/updated when a session is payment status is changed. SessionFinancial object powers the endpoint that show finacial reports in Retool.
// A sessionFinancial is a subset of a session. Object was created as a performance optimization to get the financial data to load faster in Retool.
export type SessionFinancial = Pick<
  Session,
  'payment' | 'id' | 'parkingSession' | 'billectaInvoiceActionView' | 'request' | 'sessionStatus' | 'collectiveInvoice'
>

// Deprecated LBE object
const ChildSessionSchema = z.object({
  costs: z.number(), // no parking fee just an offense
  ended_at: z.string(),
  id: z.number(),
  started_at: z.string(),
  status: z.string(),
  free_type: z.any().nullable().optional(),
  offense_type: z.string().nullable().optional(),
  slot_id: z.number().nullable().optional(),
  slot_label: z.string().nullable().optional()
})

// Deprecated LBE object
export const OnSessionClosedRequestSchema = z
  .object({
    sessionId: z.string(),
    fees: z.object({
      parkingFee: z.number(),
      offenseFee: z.number().nullable().optional(), // should be removed look at child session instead
      chargingFee: z.number().nullable().optional()
    }),
    childSessions: z.array(ChildSessionSchema).optional(),
    reason: z.enum(['EXECUTE_PAYMENT']).optional()
  })
  .passthrough()

export type OnSessionClosedRequest = z.infer<typeof OnSessionClosedRequestSchema>

export type ChildSession = z.infer<typeof ChildSessionSchema>

export type SessionSite = Omit<
  Site,
  'whitelistPlateNumberList' | 'fleetPlateNumberList' | 'gatePlateNumberList' | 'staffContactDetails' | 'cameraList'
>
